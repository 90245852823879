import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Kotlin/Native を検証してみた",
  "date": "2020-05-08T09:02:03.000Z",
  "slug": "entry/2020/05/08/180203",
  "tags": ["medley"],
  "hero": "./2020_05_08.png",
  "heroAlt": "Kotlin/Native"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、インキュベーション本部でエンジニアをしています世嘉良です。`}</p>
    <p>{`インキュベーション本部は 2020 年 2 月から新規事業の開拓などを目的に新設されたのですが、その中でも若手の部類として日々頑張っています。
CTO 平山のインタビューとともにインキュベーションチームの紹介記事が、コーポレートサイトに掲載されています。こちらもぜひご覧ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story-incubation.html"
      }}>{`https://www.medley.jp/team/creator-story-incubation.html`}</a></p>
    <p>{`さて、今回は社内で行っている勉強会：テックランチの中で「Kotlin/Native」について発表する機会があったので紹介させていただきます。`}</p>
    <h1>{`Kotlin/Native について`}</h1>
    <p>{`Kotlin は JetBrains 社によって 2011 年に発表されたプログラミング言語です。`}</p>
    <p>{`現在では Android 開発などで主流の言語となっており、多くの人に利用されていると思います。
Kotlin/Native とはそんな Kotlin を使って様々なプラットフォーム上で実行可能なファイルを生成しようというプロジェクトです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://kotlinlang.org/docs/reference/native-overview.html"
      }}>{`Kotlin/Native - Kotlin Programming Language`}</a></p>
    <p>{`仕組みとしては Kotlin のコードをもとに LLVM を生成し、それを様々なプラットフォームで利用可能なネイティブバイナリにコンパイルすることで追加のランタイムや JVM なしで動作するようにしています。`}</p>
    <p>{`サポートしているプラットフォームは下記のとおりです。`}</p>
    <ul>
      <li parentName="ul">{`iOS (arm32, arm64, simulator x86_64)`}</li>
      <li parentName="ul">{`macOS (x86_64)`}</li>
      <li parentName="ul">{`watchOS (arm32, arm64, x86)`}</li>
      <li parentName="ul">{`tvOS (arm64, x86_64)`}</li>
      <li parentName="ul">{`Android (arm32, arm64, x86, x86_64)`}</li>
      <li parentName="ul">{`Windows (mingw x86_64, x86)`}</li>
      <li parentName="ul">{`Linux (x86_64, arm32, arm64, MIPS, MIPS little endian)`}</li>
      <li parentName="ul">{`WebAssembly (wasm32)`}</li>
    </ul>
    <h1>{`コードの共通化について`}</h1>
    <p>{`Kotlin/Native のチュートリアルを参考に、Android / iOS で共通化の流れを追ってみます。`}</p>
    <p>{`作成されるプロジェクトは Android / iOS でそれぞれ "Hello, World!" を出力するシンプルなプログラムです。`}</p>
    <p><a parentName="p" {...{
        "href": "https://play.kotlinlang.org/hands-on/Targeting%20iOS%20and%20Android%20with%20Kotlin%20Multiplatform/01_Introduction"
      }}>{`Kotlin Playground: Edit, Run, Share Kotlin Code Online`}</a></p>
    <p>{`通常のプロジェクトとは別に SharedCode というプロジェクトを作成し、この中に Kotlin/Native (Android / iOS 共通のコード) を記述していきます。
それぞれのコードのレイアウトは以下の通りです。
※ SharedCode を読み込むためのマルチプラットフォームのための gradle ファイルも必要で、先程のウェブサイトに記載されています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200508/20200508170713.png",
      "alt": "20200508170713.png"
    }}></img>
    <p>{`commonMain の中に 「expect」 というキーワードを使って抽象型のようなものを宣言し、 androidMain / iOSMain といった各プラットフォームの実装の中で 「actual」 というキーワードを使用してその内容を記述します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "kotlin"
    }}><pre parentName="div" {...{
        "className": "language-kotlin"
      }}><code parentName="pre" {...{
          "className": "language-kotlin"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// commonMain`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`expect`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`platformName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// androidMain`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`actual`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`platformName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`"Android"`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// iOSMain`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`actual`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fun`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`platformName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` String `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` UIDevice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`currentDevice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`systemName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string-literal singleline"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`" "`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` UIDevice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`currentDevice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`systemVersion
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`このチュートリアルには記載ありませんが、「expect」 というキーワードはクラスや関数・アノテーションといったすべてのものに定義することができます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://kotlinlang.org/docs/reference/platform-specific-declarations.html"
      }}>{`Platform-Specific Declarations - Kotlin Programming Language`}</a></p>
    <p>{`また iOSMain 内であれば iOS の Foundation フレームワークといったように、各プラットフォームごとのフレームワークもそれぞれ利用することができます。`}</p>
    <p>{`こうしてできた、SharedCode のライブラリは Android/iOS のプロジェクトから使い慣れたライブラリと同じようにインポートすることができます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200508/20200508170931.png",
      "alt": "20200508170931.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200508/20200508170949.png",
      "alt": "20200508170949.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200508/20200508171007.png",
      "alt": "20200508171007.png"
    }}></img>
    <h1>{`Kotlin/Native の制約について`}</h1>
    <p>{`JVM 上で Kotlin を動かしていた時には標準ライブラリやメモリ管理には Java の資産を利用することができましたが、Kotlin/Native ではそれを利用することができないという制約があります。`}</p>
    <p>{`具体的にアプリを作成する際には下記の点に注意が必要でした。`}</p>
    <ul>
      <li parentName="ul">{`Java の標準ライブラリを使用しているライブラリを利用できないため Kotlin で記述されたライブラリのみが使用する`}</li>
      <li parentName="ul">{`iOS アプリを開発する際には memScope, alloc / free などを利用してメモリ管理を自分で行う必要がある`}</li>
    </ul>
    <p>{`Java の資産が利用できない Kotlin に対して心配することもありましたが、Kotlin/Native 向けに様々なライブラリが 提供されておりそれらを利用することで問題を解決しました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200508/20200508171035.png",
      "alt": "20200508171035.png"
    }}></img>
    <p>{`現状は日付管理ですらサードパーティ製のライブラリで操作する必要があるのが不満ですが、Kotlin/Native の発展によってこういった不便さは解決されるように願っています。`}</p>
    <ul>
      <li parentName="ul">{`「suspend」 関数を iOS 側から直接呼び出すことができない (コールバック関数やなどに変換して呼び出す必要がある)`}</li>
      <li parentName="ul">{`Kotlin で定義した宣言や型の一部が Swift だと別名になっている (「
objc_runtime_name」 や 「swift_name」 を参照する必要がある)`}</li>
      <li parentName="ul">{`「companion object」 や 「object」 などシングルトンの宣言に対してオブジェクトをフリーズするか 「ThreadLocal / SharedImmutable」 のアノテーションを付与する必要がある`}</li>
    </ul>
    <p>{`こちらは iOS で利用する場合の注意ですが、いくつかの Kotlin の機能が利用できなかったり、追加の記述が必要なものがあります。
詳しくは下記の資料を参照ください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://kotlinlang.org/docs/tutorials/native/apple-framework.html#kotlinnative-runtime-declarations"
      }}>{`Kotlin/Native as an Apple Framework - Kotlin Programming Language`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/JetBrains/kotlin-native/blob/985385e2fac037a1e9d8f2253139fced195c7421/IMMUTABILITY.md#L18"
      }}>{`kotlin-native/IMMUTABILITY.md`}</a></p>
    <h1>{`Kotlin/Native の利用例`}</h1>
    <p>{`まだまだ利用例が少ないため参考になる資料などがあまりないのですが、以下の実装がオープンソースとして公開されているため構成や雰囲気を掴むためにはちょうど良い資料となりました。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/JetBrains/kotlinconf-app"
        }}>{`GitHub - JetBrains/kotlinconf-app: KotlinConf Schedule Application`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/DroidKaigi/conference-app-2020"
        }}>{`GitHub - DroidKaigi/conference-app-2020: The Official Conference App for DroidKaigi 2020 Tokyo`}</a></li>
    </ul>
    <h1>{`まとめ`}</h1>
    <p>{`弊社では 「CLINICS」 というオンライン診察を行うことができるアプリをリリースしています。`}</p>
    <p>{`CLINICS を長期的に運用していくための技術調査の一環として、近年の Android 界隈で話題にあがっていた「Kotlin/Native」について検証し発表してみました。`}</p>
    <p>{`まだまだ発展途上感のある技術ですが、実用性のあるプロジェクトだと思いますのでご興味のある方はぜひ一度お試しください！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      